import { FunctionComponent, memo, useMemo } from "react";
import { Link } from "react-router-dom";
import CSS, { Property } from "csstype";

type IntercityFooterType = {
  productIds?: string;

  /** Style props */
  propAlignSelf?: Property.AlignSelf;
  propWidth?: Property.Width;

  /** Action props */
  onLink5Click?: () => void;
  onLink6Click?: () => void;
  onLink7Click?: () => void;
  onLink8Click?: () => void;
  onLink9Click?: () => void;
};

const IntercityFooter: FunctionComponent<IntercityFooterType> = memo(
  ({
    productIds,
    propAlignSelf,
    propWidth,
    onLink5Click,
    onLink6Click,
    onLink7Click,
    onLink8Click,
    onLink9Click,
  }) => {
    const footerStyle: CSS.Properties = useMemo(() => {
      return {
        alignSelf: propAlignSelf,
        width: propWidth,
      };
    }, [propAlignSelf, propWidth]);

    return (
      <div
        className="self-stretch bg-white flex flex-row py-16 px-6 items-center justify-between text-left text-xl text-black font-montserrat"
        style={footerStyle}
      >
        <div className="relative w-[287px] h-11">
          <img
            className="absolute top-[0px] left-[0px] w-[45px] h-11 object-cover"
            alt=""
            src="/logo-1@2x.png"
          />
          <div className="absolute top-[0px] left-[63px] w-56 h-11">
            <b className="absolute top-[0px] left-[0px]">Intercity</b>
            <div className="absolute top-[24px] left-[0px] text-base font-light">
              2023. Все права защищены
            </div>
          </div>
        </div>
        <div className="relative box-border w-[506px] h-px border-t-[1px] border-solid border-gainsboro" />
        <div className="flex flex-row items-center justify-start gap-[33px] text-base text-dimgray">
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/"
          >
            Главная
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/services"
          >
            Услуги
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/goods"
          >
            Товары
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/about"
          >
            О нас
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/services"
            onClick={onLink9Click}
          >
            Контакты
          </Link>
        </div>
        <div className="w-14 flex flex-row items-start justify-between">
          <img className="relative w-6 h-6" alt="" src="/uilvk.svg" />
          <img className="relative w-6 h-6" alt="" src={productIds} />
        </div>
      </div>
    );
  }
);

export default IntercityFooter;
