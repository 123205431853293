import { FunctionComponent, memo } from "react";
import { Link } from "react-router-dom";

type IntecityHeaderType = {
  /** Action props */
  onLinkClick?: () => void;
  onLink1Click?: () => void;
  onLink2Click?: () => void;
  onLink3Click?: () => void;
  onLink4Click?: () => void;
};

const IntecityHeader: FunctionComponent<IntecityHeaderType> = memo(
  ({ onLinkClick, onLink1Click, onLink2Click, onLink3Click, onLink4Click }) => {
    return (
      <div className="bg-white w-[1328px] flex flex-row py-9 px-0 box-border items-center justify-center gap-[734px] text-left text-xl text-black font-montserrat">
        <div className="relative w-[152px] h-11">
          <img
            className="absolute top-[0px] left-[0px] w-[45px] h-11 object-cover"
            alt=""
            src="/logo-1@2x.png"
          />
          <b className="absolute top-[11px] left-[63px]">Intercity</b>
        </div>
        <div className="flex flex-row items-center justify-center gap-[33px] text-base text-dimgray">
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/"
          >
            Главная
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/services"
          >
            Услуги
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/goods"
          >
            Товары
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/about"
          >
            О нас
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/contact"
          >
            Контакты
          </Link>
        </div>
      </div>
    );
  }
);

export default IntecityHeader;
