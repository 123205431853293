import { FunctionComponent } from "react";
import IntecityHeader from "../components/IntecityHeader";
import IntercityFooter from "../components/IntercityFooter";

const About: FunctionComponent = () => {
  const onLinkClick = () => {
    window.open("/");
  };

  const onLink1Click = () => {
    window.open("/services");
  };

  const onLink2Click = () => {
    window.open("/services");
  };

  const onLink3Click = () => {
    window.open("about");
  };

  const onLink4Click = () => {
    window.open("contact");
  };

  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-left text-29xl text-black font-montserrat">
      <IntecityHeader
        onLinkClick={onLinkClick}
        onLink1Click={onLink1Click}
        onLink2Click={onLink2Click}
        onLink3Click={onLink3Click}
        onLink4Click={onLink4Click}
      />
      <div className="self-stretch bg-whitesmoke h-[896px] flex flex-row py-6 px-12 box-border items-start justify-center gap-[24px]">
        <div className="h-[820px] flex flex-col items-start justify-start gap-[24px]">
          <b className="relative">О нас</b>
          <div className="relative text-xl tracking-[0.03em] leading-[105%] text-[inherit] inline-block w-[856px] h-[828px] shrink-0">
            <p className="m-0">
              Intercity – ведущий поставщик строительных материалов для
              различных потребностей в строительстве. Мы предлагаем
              высококачественные материалы из древесины, обеспечивая лучшие
              решения для наших клиентов. С нашими продуктами и услугами мы
              успешно поддерживаем строительные проекты по всей стране уже более
              двух десятилетий.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Наша компания основана в 2014 году и с тех пор зарекомендовала
              себя как надежный поставщик материалов для строительства. Мы
              гордимся тем, что сотрудничаем с более чем 135 000 компаниями по
              всему миру, включая профессиональных дизайнеров и застройщиков.
              Наш опыт и экспертиза помогают нам предоставлять лучшие
              строительные материалы по непревзойденной цене.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Мы предлагаем широкий ассортимент продукции, включая материалы для
              строительства деревянных зданий, дорог, земляных работ и многое
              другое. Наша цель - удовлетворить потребности клиентов,
              предоставляя только материалы высочайшего качества, которые
              гарантируют долговечность и надежность в каждом проекте.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Команда Intercity состоит из 35 высококвалифицированных
              сотрудников, обладающих обширными знаниями и опытом в области
              строительных материалов. Мы готовы предоставить профессиональные
              консультации и рекомендации, чтобы помочь нашим клиентам выбрать
              наиболее подходящие материалы и достичь оптимальных результатов.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Мы гордимся нашей репутацией, предоставляя высокое качество услуг
              и продуктов. Мы стремимся к удовлетворенности каждого клиента,
              предлагая прозрачность, надежность и индивидуальный подход к
              каждому проекту.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              {`Свяжитесь с нами по телефону +7 904 857 13-46 или напишите нам на почту `}
              <a
                className="text-[inherit]"
                href="mailto:inters1t1@rambler.ru"
                target="_blank"
              >
                <span className="[text-decoration:underline]">
                  inters1t1@rambler.ru
                </span>
              </a>
              , и наша дружелюбная команда поможет вам с вашими потребностями в
              строительных материалах. Мы с нетерпением ждем возможности
              сотрудничества с вами и воплощения ваших проектных идей в
              реальность.
            </p>
          </div>
        </div>
        <img
          className="relative rounded-lg w-[513px] h-[820px] object-cover"
          alt=""
          src="/rectangle-8@2x.png"
        />
      </div>
      <IntercityFooter
        productIds="/riinstagramfill2.svg"
        propAlignSelf="stretch"
        propWidth="unset"
        onLink5Click={onLinkClick}
        onLink6Click={onLink1Click}
        onLink7Click={onLink2Click}
        onLink8Click={onLink3Click}
        onLink9Click={onLink4Click}
      />
    </div>
  );
};

export default About;
