import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import IntecityHeader from "../components/IntecityHeader";
import ForestContainer from "../components/ForestContainer";
import FeaturesContainer from "../components/FeaturesContainer";
import IntercityFooter from "../components/IntercityFooter";

const Home: FunctionComponent = () => {
  const onLinkClick = () => {
    window.open("/");
  };

  const onLink1Click = () => {
    window.open("/services");
  };

  const onLink2Click = () => {
    window.open("/services");
  };

  const onLink3Click = () => {
    window.open("about");
  };

  const onLink4Click = () => {
    window.open("contact");
  };

  const onButtonsprimaryClick = () => {
    window.open("contact");
  };

  return (
    <div className="relative bg-white w-full h-[2135px] overflow-hidden flex flex-col items-center justify-start text-center text-5xl text-white font-montserrat">
      <IntecityHeader
        onLinkClick={onLinkClick}
        onLink1Click={onLink1Click}
        onLink2Click={onLink2Click}
        onLink3Click={onLink3Click}
        onLink4Click={onLink4Click}
      />
      <ForestContainer />
      <FeaturesContainer />
      <div className="self-stretch flex-1 bg-gray flex flex-col items-center justify-center">
        <div className="w-[577.4px] h-[395px] flex flex-col items-center justify-center gap-[23px]">
          <img
            className="relative rounded-[92px] w-[165px] h-[165px] object-cover"
            alt=""
            src="/rectangle-3@2x.png"
          />
          <div className="relative leading-[120%] inline-block w-[577px] h-[142px] shrink-0">
            <span className="font-semibold">“Intercity</span>
            <span>
              {" "}
              – компания, которую я использую для всех своих потребностей в
              строительных материалах для проектов. Они всегда предоставляют мне
              материалы высокого качества вовремя.”
            </span>
          </div>
          <div className="relative box-border w-[506px] h-px border-t-[1px] border-solid border-gainsboro" />
          <div className="relative text-base leading-[120%]">
            Серебряков Данила Егорович
          </div>
        </div>
      </div>
      <div className="self-stretch bg-whitesmoke h-[200px] flex flex-col items-center justify-center gap-[24px] text-left text-17xl text-black">
        <div className="relative w-[642px] h-[76px]">
          <b className="absolute top-[0px] left-[calc(50%_-_157px)] tracking-[0.03em]">
            Напишите нам!
          </b>
          <div className="absolute top-[52px] left-[calc(50%_-_321px)] text-xl tracking-[-0.02em] text-gray-3">
            Напишите нам и будьте в курсе самых выгодных предложений.
          </div>
        </div>
        <Link
          className="cursor-pointer [text-decoration:none] relative w-[140px] h-10 text-center text-base text-gray-5"
          to="/contact"
          onClick={onButtonsprimaryClick}
        >
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-gray" />
          <div className="absolute top-[calc(50%_-_10px)] left-[22.14%] font-medium">
            Контакты
          </div>
        </Link>
      </div>
      <IntercityFooter
        productIds="/riinstagramfill3.svg"
        propAlignSelf="unset"
        propWidth="1440px"
        onLink5Click={onLinkClick}
        onLink6Click={onLink1Click}
        onLink7Click={onLink2Click}
        onLink8Click={onLink3Click}
        onLink9Click={onLink4Click}
      />
    </div>
  );
};

export default Home;
