import { FunctionComponent } from "react";
import IntecityHeader from "../components/IntecityHeader";
import OfficeLocationContainer from "../components/OfficeLocationContainer";
import ContactForm from "../components/ContactForm";
import IntercityFooter from "../components/IntercityFooter";

const Contact: FunctionComponent = () => {
  const onLinkClick = () => {
    window.open("/");
  };

  const onLink1Click = () => {
    window.open("/services");
  };

  const onLink2Click = () => {
    window.open("/services");
  };

  const onLink3Click = () => {
    window.open("about");
  };

  const onLink4Click = () => {
    window.open("contact");
  };

  return (
    <div className="relative bg-white w-full flex flex-col items-center justify-start text-left text-5xl text-black font-montserrat">
      <IntecityHeader
        onLinkClick={onLinkClick}
        onLink1Click={onLink1Click}
        onLink2Click={onLink2Click}
        onLink3Click={onLink3Click}
        onLink4Click={onLink4Click}
      />
      <div className="self-stretch bg-whitesmoke flex flex-col py-6 px-12 items-center justify-start gap-[24px]">
        <iframe
          className="[border:none] self-stretch relative h-[430px] overflow-hidden shrink-0"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2247.7996969415763!2d37.651885277196676!3d55.70985339529805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54b33f224fd5b%3A0x72cee4b0bb4e8d15!2z0YPQuy4g0JvQtdC90LjQvdGB0LrQsNGPINCh0LvQvtCx0L7QtNCwLCAyNiwg0JzQvtGB0LrQstCwLCDQoNC-0YHRgdC40Y8sIDExNTI4MA!5e0!3m2!1sru!2sde!4v1688192082156!5m2!1sru!2sde"
        />
        <div className="self-stretch flex flex-row pt-0 px-0 pb-12 items-center justify-between">
          <OfficeLocationContainer
            prop="Наш офис:"
            icomoonFreemap="/icomoonfreemap.svg"
            prop1="115280, город Москва, ул Ленинская Слобода, д. 26, помещ XXXII-51 ком 1"
          />
          <OfficeLocationContainer
            prop="Наш телефон"
            icomoonFreemap="/icomoonfreephone.svg"
            prop1="+7 (904) 857 13-46"
            propWidth="209px"
          />
        </div>
        <div className="relative box-border w-[581px] h-px border-t-[1px] border-solid border-gray-4" />
        <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
          <b className="relative">Наши реквизиты</b>
          <div className="relative text-[inherit] font-inherit">
            <ul className="m-0 pl-[21px]">
              <li className="mb-0">
                Полное наименование: Общество с ограниченной ответственностью
                «Интерсити»
              </li>
              <li className="mb-0">ИНН/КПП: 7725257951 / 772501001</li>
              <li className="mb-0">ОГРН: 1147748134490</li>
              <li className="mb-0">
                Р/С: 40702978902230000006 в АО «АЛЬФА-БАНК»
              </li>
              <li className="mb-0">БИК: 044525593</li>
              <li>К/С: 30101810200000000593</li>
            </ul>
          </div>
        </div>
        <ContactForm />
      </div>
      <IntercityFooter
        productIds="/riinstagramfill1.svg"
        propAlignSelf="stretch"
        propWidth="unset"
        onLink5Click={onLinkClick}
        onLink6Click={onLink1Click}
        onLink7Click={onLink2Click}
        onLink8Click={onLink3Click}
        onLink9Click={onLink4Click}
      />
    </div>
  );
};

export default Contact;
