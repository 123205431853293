import { FunctionComponent, memo } from "react";
import HighQualityCardContainer from "./HighQualityCardContainer";

const FeaturesContainer: FunctionComponent = memo(() => {
  return (
    <div className="self-stretch flex-1 bg-whitesmoke flex flex-col py-11 px-32 items-center justify-start gap-[24px] text-left text-xl text-black font-montserrat">
      <div className="self-stretch flex flex-row items-center justify-center gap-[255px]">
        <div className="relative w-[383px] h-[108px]">
          <div className="absolute top-[0px] left-[0px] leading-[100%] font-light">
            Наши преимущества
          </div>
          <b className="absolute top-[28px] left-[0px] text-17xl leading-[109.9%]">
            <p className="m-0">{`Найдите для себя `}</p>
            <p className="m-0">лучшие материалы</p>
          </b>
        </div>
        <div className="relative text-base leading-[125%] text-right inline-block w-[529px] h-[143px] shrink-0">
          Мы понимаем важность лучших материалов и их ценность для вашего
          проекта. Наши продукты широко используются профессиональными
          дизайнерами и застройщиками из более чем 135 000 компаний по всему
          миру. Независимо от того, является ли это крупным инфраструктурным
          проектом или реконструкцией недвижимости, мы предоставляем лучшие
          материалы по непревзойденной цене.
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-center gap-[21px] text-center">
        <HighQualityCardContainer
          prop="Высокое качество"
          intercity="Intercity предлагает только материалы высочайшего качества. Это обеспечивает долговечность и надежность ваших строительных проектов."
          iconamooncheckSquareFill="/iconamoonchecksquarefill.svg"
        />
        <HighQualityCardContainer
          prop="Широкий ассортимент"
          intercity="Intercity предлагает разнообразие строительных материалов из древесины, подходящих для различных типов проектов."
          iconamooncheckSquareFill="/iconamoon3dfill.svg"
          propLeft="17.06%"
        />
        <HighQualityCardContainer
          prop="Консультирование"
          intercity="Мы готовы предоставить профессиональные советы, помогая клиентам выбрать наиболее подходящие материалы для ваших проектов."
          iconamooncheckSquareFill="/iconamoonheadphonefill.svg"
          propLeft="22.83%"
        />
      </div>
    </div>
  );
});

export default FeaturesContainer;
