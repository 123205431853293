import { FunctionComponent } from "react";
import IntecityHeader from "../components/IntecityHeader";
import IntercityFooter from "../components/IntercityFooter";

const Goods: FunctionComponent = () => {
  const onLinkClick = () => {
    window.open("/");
  };

  const onLink1Click = () => {
    window.open("/services");
  };

  const onLink2Click = () => {
    window.open("/services");
  };

  const onLink3Click = () => {
    window.open("about");
  };

  const onLink4Click = () => {
    window.open("contact");
  };

  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-center text-29xl text-black font-montserrat">
      <IntecityHeader
        onLinkClick={onLinkClick}
        onLink1Click={onLink1Click}
        onLink2Click={onLink2Click}
        onLink3Click={onLink3Click}
        onLink4Click={onLink4Click}
      />
      <div className="self-stretch bg-whitesmoke flex flex-col py-6 px-0 items-center justify-start gap-[24px]">
        <b className="relative">Наши товары</b>
        <div className="self-stretch flex flex-row py-0 px-16 items-start justify-center gap-[24px] text-left text-5xl">
          <div className="flex-1 flex flex-col items-center justify-start gap-[34px]">
            <img
              className="relative w-[644px] h-[370px] object-cover"
              alt=""
              src="/media@2x.png"
            />
            <div className="flex flex-col items-center justify-start gap-[16px]">
              <b className="relative leading-[135.94%] inline-block w-[644px]">
                Лесоматериалы
              </b>
              <div className="relative text-sm leading-[135.94%] inline-block w-[644px]">
                <p className="m-0">
                  Лесоматериалы являются неотъемлемой частью строительной
                  индустрии, поскольку они широко используются для возведения
                  каркасов зданий, укладки полов, создания перегородок и
                  потолков, а также для изготовления мебели и других предметов
                  интерьера. Они обладают прочностью, надежностью и эстетической
                  привлекательностью, что делает их популярным выбором для
                  многих строительных проектов.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Качество лесоматериалов зависит от типа древесины,
                  используемой в их производстве, и методов обработки.
                  Высококачественные лесоматериалы должны быть сухими,
                  устойчивыми к влаге, иметь правильную геометрию и не содержать
                  дефектов, таких как трещины, вмятины или гниль. Это
                  обеспечивает долговечность и надежность конечных конструкций.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Лесоматериалы широко используются не только в строительстве,
                  но и в других областях, таких как производство мебели,
                  упаковочной продукции, судостроение и изготовление различных
                  изделий из дерева. Они предоставляют возможность создания
                  красивых и функциональных продуктов, отвечающих требованиям
                  современных стандартов качества и безопасности.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Торговля оптовыми лесоматериалами позволяет предприятиям и
                  организациям приобретать необходимые материалы в больших
                  объемах по выгодным ценам. Кроме того, оптовые поставки обычно
                  обеспечивают широкий выбор различных видов и размеров
                  лесоматериалов, что позволяет удовлетворить потребности разных
                  типов строительных проектов.
                </p>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-start gap-[34px]">
            <img
              className="relative w-[644px] h-[370px] object-cover"
              alt=""
              src="/media1@2x.png"
            />
            <div className="flex flex-col items-center justify-start gap-[16px]">
              <b className="relative leading-[135.94%] inline-block w-[644px]">
                Cтройматериалы
              </b>
              <div className="relative text-sm leading-[135.94%] inline-block w-[644px]">
                <p className="m-0">
                  Строительные материалы, также известные как строительные
                  сырьевые материалы или строительные компоненты, представляют
                  собой разнообразные материалы, используемые для строительства,
                  ремонта и модернизации зданий и сооружений. Они служат основой
                  для создания прочных и функциональных конструкций.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Основные строительные материалы: К ним относятся цемент,
                  кирпич, бетонные блоки, арматурные стержни, металлические
                  профили и другие материалы, которые используются для
                  возведения основных структур зданий, таких как фундаменты,
                  стены и перекрытия.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Отделочные материалы: Это материалы, применяемые для придания
                  окончательного вида и защиты здания. Они включают в себя обои,
                  краски, штукатурку, гипсокартонные плиты, керамическую плитку,
                  ламинат и другие покрытия для стен, полов и потолков.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Кровельные и изоляционные материалы: Кровельные материалы,
                  такие как керамическая черепица, шифер, металлические листы,
                  рубероид и мембраны, используются для создания защитного слоя
                  над зданием. Изоляционные материалы, такие как минеральная
                  вата, пенополистирол, пеноплекс и другие, применяются для
                  теплоизоляции и звукоизоляции здания.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Оконные и дверные конструкции: Включают в себя оконные рамы,
                  двери, стекла, оконные и дверные фурнитуры и аксессуары,
                  которые обеспечивают безопасность, вентиляцию и эстетическое
                  оформление здания.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Санитарно-техническое оборудование: Водопроводные и
                  канализационные системы, сантехническая арматура, санфаянс,
                  душевые кабины, ванны, раковины, смесители и другие
                  компоненты, используемые в ванных комнатах и кухнях.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <IntercityFooter
        productIds="/riinstagramfill.svg"
        onLink5Click={onLinkClick}
        onLink6Click={onLink1Click}
        onLink7Click={onLink2Click}
        onLink8Click={onLink3Click}
        onLink9Click={onLink4Click}
      />
    </div>
  );
};

export default Goods;
