import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import IntecityHeader from "../components/IntecityHeader";
import IntercityFooter from "../components/IntercityFooter";

const Services: FunctionComponent = () => {
  const onLinkClick = () => {
    window.open("/");
  };

  const onLink1Click = () => {
    window.open("/services");
  };

  const onLink2Click = () => {
    window.open("/services");
  };

  const onLink3Click = () => {
    window.open("about");
  };

  const onLink4Click = () => {
    window.open("contact");
  };

  const onButtonsprimaryClick = () => {
    window.open("contact");
  };

  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-left text-29xl text-gray-6 font-montserrat">
      <IntecityHeader
        onLinkClick={onLinkClick}
        onLink1Click={onLink1Click}
        onLink2Click={onLink2Click}
        onLink3Click={onLink3Click}
        onLink4Click={onLink4Click}
      />
      <div className="self-stretch bg-whitesmoke h-[1044px] flex flex-col py-6 px-0 box-border items-center justify-start gap-[24px]">
        <b className="relative inline-block text-black w-[340px] h-[59px] shrink-0">
          Наши услуги
        </b>
        <div className="relative w-[962px] h-[431px] text-13xl">
          <div className="absolute top-[0px] left-[0px] w-[606px] h-[431px] overflow-hidden flex flex-col pt-6 px-6 pb-0 box-border items-start justify-start gap-[16px]">
            <img
              className="absolute my-0 mx-[!important] top-[0px] left-[0px] w-[606px] h-[431px] object-cover z-[0]"
              alt=""
              src="/bigservicebackground@2x.png"
            />
            <div className="relative leading-[80%] font-medium z-[1]">
              <p className="m-0">{`Поставка `}</p>
              <p className="m-0">строительных материалов</p>
            </div>
            <div className="relative text-xl leading-[100%] inline-block w-[550px] z-[2]">
              Древесные бруски, доски, фанеру, фанерные плиты, деревянные полы.
            </div>
          </div>
          <div className="absolute top-[0px] left-[625px] w-[337px] h-[258px] flex flex-col p-6 box-border items-center justify-center gap-[10px] bg-[url(/public/smallservice@3x.png)] bg-cover bg-no-repeat bg-[top] text-center text-5xl">
            <img
              className="absolute my-0 mx-[!important] top-[0px] left-[0px] w-[337px] h-[258px] object-cover z-[0]"
              alt=""
              src="/smallservicebackground@2x.png"
            />
            <div className="relative leading-[80%] font-medium inline-block w-[302px] z-[1]">
              <p className="m-0">Строительство</p>
              <p className="m-0">домов</p>
            </div>
          </div>
        </div>
        <div className="relative w-[962px] h-[431px] text-center text-5xl">
          <div className="absolute top-[0px] left-[0px] w-[337px] h-[258px] flex flex-col p-6 box-border items-center justify-center gap-[10px] bg-[url(/public/smallservice1@3x.png)] bg-cover bg-no-repeat bg-[top]">
            <img
              className="absolute my-0 mx-[!important] top-[0px] left-[0px] w-[337px] h-[258px] object-cover z-[0]"
              alt=""
              src="/smallservicebackground1@2x.png"
            />
            <div className="relative leading-[80%] font-medium inline-block w-[302px] z-[1]">
              <p className="m-0">Строительство</p>
              <p className="m-0">дорог</p>
            </div>
          </div>
          <div className="absolute top-[0px] left-[356px] w-[606px] h-[431px] flex flex-col pt-6 px-6 pb-0 box-border items-start justify-start gap-[16px] bg-[url(/public/bigservice@3x.png)] bg-cover bg-no-repeat bg-[top] text-left text-13xl">
            <img
              className="absolute my-0 mx-[!important] top-[0px] left-[0px] w-[606px] h-[431px] object-cover z-[0]"
              alt=""
              src="/bigservicebackground1@2x.png"
            />
            <div className="relative leading-[80%] font-medium z-[1]">
              Земельные работы
            </div>
            <div className="relative text-xl leading-[100%] inline-block w-[550px] z-[2]">
              Разработка местности, землеройные работы, устройство дренажной
              системы и прочее.
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch bg-black h-[195px] flex flex-col items-center justify-center gap-[24px] text-center text-17xl text-white">
        <div className="relative w-[684px] h-[76px]">
          <b className="absolute top-[0px] left-[calc(50%_-_178px)] tracking-[0.03em]">
            Заинтересовали?
          </b>
          <div className="absolute top-[52px] left-[calc(50%_-_342px)] text-xl tracking-[-0.02em] text-gray-3 text-left">
            Позвоните нашему менеджеру для более подробной информации
          </div>
        </div>
        <Link
          className="cursor-pointer [text-decoration:none] relative w-[140px] h-10 text-base text-gray-5"
          to="/contact"
          onClick={onButtonsprimaryClick}
        >
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-gray" />
          <div className="absolute top-[calc(50%_-_10px)] left-[22.14%] font-medium">
            Контакты
          </div>
        </Link>
      </div>
      <IntercityFooter
        productIds="/riinstagramfill3.svg"
        propAlignSelf="stretch"
        propWidth="unset"
        onLink5Click={onLinkClick}
        onLink6Click={onLink1Click}
        onLink7Click={onLink2Click}
        onLink8Click={onLink3Click}
        onLink9Click={onLink4Click}
      />
    </div>
  );
};

export default Services;
