import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Goods from "./pages/Goods";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Services from "./pages/Services";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = 'ООО "Интерсити"';
        metaDescription =
          "Intercity – ведущий поставщик строительных материалов из древесины             для любых строительных проектов. Мы предоставляем материалы             высочайшего качества более двух десятилетий и успешно завершили             проекты по всей стране. Мы предлагаем древесные материалы для             строительства деревянных зданий, дорог и земляных работ, а также             профессиональные консультации для каждого проекта.";
        break;
      case "/goods":
        title = 'Товары - ООО "Интерсити"';
        metaDescription = "";
        break;
      case "/contact":
        title = 'Контакты - ООО "Интерсити"';
        metaDescription = "";
        break;
      case "/about":
        title = 'О нас - ООО "Интерсити"';
        metaDescription = "";
        break;
      case "/services":
        title = 'Услуги - ООО "Интерсити"';
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/goods" element={<Goods />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />
    </Routes>
  );
}
export default App;
