import { FunctionComponent, memo, useMemo } from "react";
import CSS, { Property } from "csstype";

type OfficeLocationContainerType = {
  prop?: string;
  icomoonFreemap?: string;
  prop1?: string;

  /** Style props */
  propWidth?: Property.Width;
};

const OfficeLocationContainer: FunctionComponent<OfficeLocationContainerType> =
  memo(({ prop, icomoonFreemap, prop1, propWidth }) => {
    const groupDivStyle: CSS.Properties = useMemo(() => {
      return {
        width: propWidth,
      };
    }, [propWidth]);

    return (
      <div className="h-[66px] flex flex-col items-start justify-start gap-[8px] text-left text-5xl text-black font-montserrat">
        <div className="relative w-[171px] h-[29px]" style={groupDivStyle}>
          <b className="absolute top-[0px] left-[33px]">{prop}</b>
          <img
            className="absolute top-[3px] left-[0px] w-6 h-6"
            alt=""
            src={icomoonFreemap}
          />
        </div>
        <div className="relative text-base">{prop1}</div>
      </div>
    );
  });

export default OfficeLocationContainer;
