import { FunctionComponent, memo } from "react";
import { Link } from "react-router-dom";

const ForestContainer: FunctionComponent = memo(() => {
  const onBackgroundClick = () => {
    window.open("services");
  };

  return (
    <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[83px] text-left text-29xl text-black font-montserrat">
      <div className="flex flex-col items-start justify-start gap-[94px]">
        <div className="relative w-[706px] h-[313px]">
          <b className="absolute top-[0px] left-[0px] leading-[109.9%] inline-block w-[706px]">
            <p className="m-0">Продажа лесоматериалов,</p>
            <p className="m-0">строительство домов</p>
            <p className="m-0">и земельные работы.</p>
          </b>
          <div className="absolute top-[183px] left-[1px] text-base tracking-[0.03em] inline-block w-[647px] h-[130px]">
            Intercity – ведущий поставщик строительных материалов из древесины
            для любых строительных проектов. Мы предоставляем материалы
            высочайшего качества более двух десятилетий и успешно завершили
            проекты по всей стране. Мы предлагаем древесные материалы для
            строительства деревянных зданий, дорог и земляных работ, а также
            профессиональные консультации для каждого проекта.
          </div>
        </div>
        <Link
          className="cursor-pointer [text-decoration:none] relative w-[140px] h-10 text-base text-gray-5"
          to="/services"
        >
          <div
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-green-1 cursor-pointer"
            onClick={onBackgroundClick}
          />
          <div className="absolute top-[calc(50%_-_10px)] left-[30%] font-medium">
            Услуги
          </div>
        </Link>
      </div>
      <img
        className="relative rounded-lg w-[468px] h-[490px] object-cover"
        alt=""
        src="/image-1@2x.png"
      />
    </div>
  );
});

export default ForestContainer;
