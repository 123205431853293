import { FunctionComponent, memo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";

const ContactForm: FunctionComponent = memo(() => {
  return (
    <form
      className="self-stretch h-[338px] flex flex-col items-start justify-start gap-[24px]"
      action="https://formsubmit.co/inters1t1@rambler.ru"
    >
      <b className="relative text-5xl font-montserrat text-black text-left">
        Обратная связь
      </b>
      <Form.Group className="w-[250px] [border:none] bg-[transparent]">
        <Form.Control type="text" name="name" placeholder="Ваше имя" />
      </Form.Group>
      <Form.Group className="w-[250px] [border:none] bg-[transparent]">
        <Form.Control type="text" name="email" placeholder="Ваш email" />
      </Form.Group>
      <Form.Group className="w-[395px]">
        <Form.Control as="textarea" name="text" placeholder="Ваше сообщение" />
      </Form.Group>
      <button className="cursor-pointer [border:none] p-0 bg-[transparent] relative w-[140px] h-10">
        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-green-1" />
        <div className="absolute top-[calc(50%_-_10px)] left-[18.57%] text-base font-medium font-montserrat text-gray-5 text-center">
          Отправить
        </div>
      </button>
    </form>
  );
});

export default ContactForm;
