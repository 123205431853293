import { FunctionComponent, memo, useMemo } from "react";
import CSS, { Property } from "csstype";

type HighQualityCardContainerType = {
  prop?: string;
  intercity?: string;
  iconamooncheckSquareFill?: string;

  /** Style props */
  propLeft?: Property.Left;
};

const HighQualityCardContainer: FunctionComponent<HighQualityCardContainerType> =
  memo(({ prop, intercity, iconamooncheckSquareFill, propLeft }) => {
    const bStyle: CSS.Properties = useMemo(() => {
      return {
        left: propLeft,
      };
    }, [propLeft]);

    return (
      <div className="relative w-[381px] h-[299px] text-center text-xl text-black font-montserrat">
        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] flex flex-row items-start justify-start">
          <div className="relative rounded bg-white w-[381px] h-[299px]" />
        </div>
        <b
          className="absolute top-[50.17%] left-[23.62%] leading-[100%]"
          style={bStyle}
        >
          {prop}
        </b>
        <div className="absolute w-[80.84%] top-[59.87%] left-[9.45%] text-base leading-[100%] inline-block">
          {intercity}
        </div>
        <img
          className="absolute h-[32.11%] w-[25.2%] top-[13.04%] right-[37.01%] bottom-[54.85%] left-[37.8%] max-w-full overflow-hidden max-h-full"
          alt=""
          src={iconamooncheckSquareFill}
        />
      </div>
    );
  });

export default HighQualityCardContainer;
